<template>
  <div>
    <!-- begin::Entry -->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid py-8">
        <!--begin::Education-->
        <div class="d-flex flex-row">
          <UserNav activePage="bestpracticies" activeTab="4"></UserNav>
          <!--begin::Content-->
          <div class="flex-row-fluid ml-lg-8">

            <div class="pb-10">
              <!--begin::Section-->
              <div>
                <div class="card">
                  <div class="card-body">
                    <div>
                      <h3 class="font-weight-bolder text-dark fs-15 py-5">Best Practices</h3>
                      <div class="">
                        <b-card no-body>
                          <b-tabs pills card vertical>
                            <b-tab title="Bitrate" active><b-card-text>
                                <div>
                                  <h2 class="font-size-h4">Dynamic Bitrate</h2>
                                  <p>
                                    In the latest update to Streamlabs OBS, they included a
                                    feature called Dynamic Bitrate. This feature allows
                                    their software to automatically adjusts your bitrate
                                    based on network conditions to prevent dropping
                                    frames.
                                  </p>
                                  <figure>
                                    <div>
                                      <div>
                                        <iframe class="s t u ey aj" title="Dynamic Bitrate" src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com
                              %2Fembed%2FAQiMqO-aSoI%3Ffeature%3Doembed&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch
                              %3Fv%3DAQiMqO-aSoI&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FAQiMqO-aSoI%2Fhqdefault.jpg
                              &amp;key=a19fcc184b9711e1b4764040d3dc5c07&amp;type=text%2Fhtml&amp;schema=youtube"
                                          frameborder="0" scrolling="auto" allowfullscreen=""></iframe>
                                      </div>
                                    </div>
                                  </figure>
                                  <p>
                                    Many external factors can affect a user’s connection.
                                    Network congestion is the main culprit. Having a
                                    static bitrate can cause frames to drop because your
                                    connection is unable to keep up and will drop frames
                                    to improve the stability of your stream and minimize
                                    latency.
                                  </p>
                                  <p>
                                    Several reasons can cause network congestion. An
                                    unstable connection to your ISP or streaming over wifi
                                    is most common. Dynamic Bitrate combats this by
                                    automatically adjusting your bitrate when you have
                                    issues with your internet connection.
                                  </p>
                                  <h2><strong>Here’s how it works:</strong></h2>
                                  <ol>
                                    <li>Set a “target bitrate”.</li>
                                    <li>
                                      When you experience network issues, Streamlabs OBS
                                      will lower your bitrate until you’re not dropping
                                      frames anymore.
                                    </li>
                                    <li>
                                      Once we detect that the network issues are gone,
                                      your bitrate should gradually go back up to the
                                      target bitrate you had set.
                                    </li>
                                  </ol>
                                  <h2><strong>How to enable Dynamic Bitrate:</strong></h2>
                                  <p>
                                    To enable Dynamic Bitrate, make sure you download the
                                    latest version of Streamlabs OBS. Navigate to Settings
                                    &gt; Advanced and toggle the box next to the option
                                    that says “Dynamically change bitrate when dropping
                                    frames while streaming”.
                                  </p>
                                  <figure>
                                    <div class="mw-60">
                                      <img class="w100 h100"
                                        src="https://ingomucdn.s3.amazonaws.com/image/png/0_0jtsaNx66VkjtKgN.png"
                                        alt="0*0jtsaNx66VkjtKgN.png" />
                                    </div>
                                  </figure>
                                  <p>
                                    You can download
                                    <a href="https://streamlabs.com/slobs/download" target="_blank"
                                      rel="noreferrer noopener">Streamlabs OBS</a>
                                    here.
                                  </p>
                                </div>
                              </b-card-text>
                            </b-tab>
                            <b-tab title="Portrait Mode"><b-card-text>
                                <div>
                                  <h2 class="font-size-h4">Portait Mode</h2>
                                  <p>
                                    Here is a helpful video tutorial that wil guided you through the process of setting up
                                    portrait mode in Streamlabs OBS.
                                  </p>
                                  <div style="width: 70%; height: auto;">
                                    <video ref="videoPlayer" class="video-js"></video>
                                  </div>
                                </div>
                              </b-card-text></b-tab>
                          </b-tabs>
                        </b-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Section-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
  name: "bestpractices",
  components: {
    UserNav
  },
  data() {
    return {
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        muted: true,
        height: "200",
        width: "200",
        aspectRatio: "16:9",
        responsive: true,
        fluid: true,
        sources: [
          {
            src:
              'https://ingomucdn.s3.amazonaws.com/video/Portrait.mp4',
            type: 'video/mp4'
          }
        ]
      }
    }
  },
  mounted() {
    this.$store.commit("CHANGE_TABINDEX", { tabIndex: 3 });
    this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 12 });

    this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>

<style scoped>
.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.mw-60 {
  max-width: 60% !important;
}
</style>